<script setup lang="ts">
import type { Pagination } from "@gasparigit/omnia-sdk";
import type { Component } from "vue";
import { cn } from "~/lib/utils";
import { isNotAllowed } from "~/lib/utils/contents";
import type { CreateLinkConfig, ListableContent } from "~/utils/types";

const props = withDefaults(
  defineProps<{
    title?: string;
    icon?: Component;
    linkConfig?: CreateLinkConfig;
    showAreas?: boolean;
    showChildren?: boolean;
    showParents?: boolean;
    compact?: boolean;
    hasSide?: boolean;
    loading?: boolean;
  }>(),
  {
    hasSide: true,
  }
);

const data = defineModel<Pagination<ListableContent> | { allowed: boolean } | null | false>("data");
const page = defineModel<number>("page");

function getKey(content: ListableContent) {
  if ("contentable_type" in content && content.contentable_type === "scadenza") {
    return `${content.id}-${content.date}`;
  }

  return `${content.id}`;
}
</script>

<template>
  <div :class="cn('md:grid grid-cols-4 mt-16 mb-10 gap-x-10 xl:gap-x-20', $attrs.class)">
    <div :class="cn('col-span-4', hasSide && 'side' in $slots && 'col-span-4 lg:col-span-3 lg:col-start-2')">
      <div class="border-b pb-2 flex items-center justify-between" v-if="title">
        <h2 class="flex items-center text-4xl font-extrabold">
          <component :is="icon" class="text-muted-foreground w-12 h-12 flex-none me-2" />
          <span>{{ title }}</span>
        </h2>
        <slot name="cta"></slot>
      </div>
      <slot name="header"></slot>
    </div>
    <div class="col-span-2 lg:col-span-1 mb-16 md:mb-0 mt-10 md:mt-5 lg:mt-0" v-if="hasSide && 'side' in $slots">
      <slot name="side"></slot>
    </div>
    <div :class="cn('col-span-4 mt-5', hasSide && 'side' in $slots && 'col-span-2 lg:col-span-3')">
      <slot name="list-header"></slot>

      <template v-if="loading === true || (loading === undefined && !data)">
        <div class="w-full mb-7 space-y-1" v-for="i in Array(5).keys()">
          <Skeleton class="w-full h-8 rounded-lg mb-2" />
          <Skeleton class="w-2/3 h-4 rounded-lg" />
          <Skeleton class="w-1/2 h-4 rounded-lg" />
          <Skeleton class="w-1/3 h-4 rounded-lg" />
        </div>
      </template>
      <template v-else>
        <template name="not-allowed" v-if="isNotAllowed(data)">
          <FrontUnauthorized :link-config="linkConfig" />
        </template>
        <div class="mb-16" v-else-if="data">
          <div class="flex flex-col gap-y-6">
            <FrontContentItem
              :link-config="linkConfig"
              :show-children="showChildren"
              :show-parents="showParents"
              :content="content"
              :show-areas="showAreas"
              v-for="content in data.data"
              :key="getKey(content)"
            />
            <div v-if="!data?.data?.length">Nessun contenuto presente</div>
            <MetaPagination v-model:page="page" :meta="data.meta" />
          </div>
        </div>
      </template>

      <slot name="list-footer"></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>
